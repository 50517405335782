import Email from './Elements/Email';

const Form4 = ({ forms, attributes, divRef, handleCopy, copied }) => {
    const { elements } = attributes;
    const { text } = elements;

    return text && <div className='form4'>
        <div className='formMainArea'>
            <div className='content' onClick={e => { e.preventDefault(); handleCopy(); }}>
                <Email attributes={attributes} divRef={divRef} forms={forms} copied={copied} />
            </div>
            <div className='hoverContent'>
                {copied ? "Copied" : "Click to copy"}
            </div>
        </div>
    </div>

}
export default Form4;